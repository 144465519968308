import React from "react"
import styled from "@emotion/styled"
import { MDXProvider } from "@mdx-js/react"
import mdxComponents from "./mdxComponents"
import NavBar from "../common/NavBar"
import Copyright from "../common/CopyRight"
import { Container, Button } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 767px) {
    display: block;
  }
`

const Content = styled("main")`
  display: flex;
  flex-grow: 1;
  margin: 0px 88px;
  margin-top: 0rem;

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
    margin: 0 10px;
    margin-top: 0rem;
  }
`

const BackButton = styled("div")`
  margin: 2rem 0 0 2rem;
`

const MaxWidth = styled("div")`
  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`
const LeftSideBarWidth = styled("div")`
  width: 298px;
`
const RightSideBarWidth = styled("div")`
  width: 224px;
`

const PageContainer = styled("main")`
  position: relative;
  min-height: 100vh;
`

const ContainerWrap = styled("main")`
  padding-bottom: 20px;
`

const Footer = styled("main")`
  position: absolute;
  bottom: 0;
  width: 100%;
`
const Layout = ({ children, location }) => (
  <>
    <PageContainer>
      <ContainerWrap>
        <NavBar />
        <Container>
          <BackButton>
            <Button
              size="large"
              startIcon={<ArrowBackIcon />}
              sx={{ color: "black" }}
              href="/docs"
            >
              Docs
            </Button>
          </BackButton>

          <MDXProvider components={mdxComponents}>
            <Wrapper>
              {/* <LeftSideBarWidth className={'hiddenMobile'}>
          <Sidebar location={location} />
        </LeftSideBarWidth> */}
              <Content>
                <MaxWidth>{children}</MaxWidth>
              </Content>
              {/* <RightSideBarWidth className={'hiddenMobile'}>
          <RightSidebar location={location} />
        </RightSideBarWidth> */}
            </Wrapper>
          </MDXProvider>
        </Container>
        <Footer>
          <Copyright />
        </Footer>
      </ContainerWrap>
    </PageContainer>
  </>
)

export default Layout
